<template>
	<Portal to="modal-content">
		<ValidationObserver ref="observer" tag="form" v-slot="{ invalid }" @submit.prevent="handleSubmit">
            <section>
                <p>
                    <small>
                        We found more than one document, please select from the list below to download.
                    </small>
                </p>

                <StsButtonLink v-for="(file, index) in files"
                               :key="`download-link-${index}`"
                               :id="file.fileName"
                               :name="file.fileName" 
                               :title="formatFileName(file.fileName)"
                               type="text"
                               :fullwidth="true"
                               :target="file.fileName"
                               :url="file.downloadLink"/>

                <StsButton fullwidth
                           lowercase
                           small
                           style="margin-bottom: 1rem"
                           title="Close"
                           @click="handleClose()" />
            </section>
		</ValidationObserver>
	</Portal>
</template>





<script>
    import { Portal } from 'portal-vue';
    import { mapActions } from 'vuex';
    import { ValidationObserver } from 'vee-validate';
    import { createToastInterface } from 'vue-toastification';
    import { StsButton, StsButtonLink } from '@/components/SwitchThink';
    import showSpinner from '@/mixins/showSpinner';
    import { toastConfig } from '@/utils';

const toast = createToastInterface(toastConfig);

    export default {
        name: 'DownloadModal',
        mixins: [showSpinner],
        components: {
            Portal,
            StsButton,
            ValidationObserver,
            StsButtonLink
        },
        props: {
            files: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            };
        },
        async created() {

            return this.triggerModal({
                isOpen: true,
                title: 'File Downloads',
                smallTitle: true,
                preventClose: true
            });

        },
        computed: {

        },
        beforeDestroy() {
            return this.triggerModal({ isOpen: false });
        },
        methods: {
            ...mapActions({
                triggerModal: 'modal/triggerModal'
            }),
            async handleClose() {
                try {

                    this.$emit('close');

                } catch (error) {

                    return toast.error(error);

                }
            },
            formatFileName(fileName) {

                let result = fileName;

                if (fileName && fileName.length > 30) {
                    result = `${fileName.slice(0, 29)}...`;
                }

                return result;

            }
        }
    };
</script>





<style lang="scss" scoped>
.joint-owners {
	display: grid;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(6.3125rem, 1fr));

	h2 {
		margin-top: 1rem;
		font-size: 1.75rem;
		color: var(--primary);
		font-weight: 400;
		transition: color 0.5s;
	}

	p {
		color: var(--primary);
		font-weight: 300;
		transition: color 0.5s;
	}

	button.selected p,
	button.selected h2 {
		color: var(--success);
	}
}
</style>